@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');


element > element{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body{
    font-family: 'Noto Sans KR', sans-serif !important;
}
.Kontakt{
    background-color: #fafafa;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 40px;
    padding: 0 20px 40px 20px;
}
#color{
    color: #000;
}
.konbanner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.koncard-container{
    display: grid;
    width: 850px;
    margin: 0 0 0 0;
}
.koncard-img{
    width: 100%;
    height: 380px;
    background: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: solid 3px #fafafa;
    z-index: 1;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.konbanner h2{
    padding-bottom: 40px;
    margin-bottom: 20px;
}
.koncard-content{
    background: #fff;
    height: 103%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.koncard-content .schreibuns{
    text-align: center;
    color: #000;
    padding: 25px 0 10px 0;
    font-size: 26px;
    font-weight: 500;
}
.konFormWrap{
    margin-top: 30px;
}
.konform-row{
    display: flex;
    flex-wrap: nowrap !important;
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    align-items: center;
}

form select, form input{
    display: block;
    width: 100%;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    border-bottom: 1px solid #eee;
    font-weight: 300;
}
form input[type = text], form input[type = number], form input[type = date], select, textarea{
    color: #000;
}
form input::placeholder, form textarea::placeholder{
    color: #464141;
}
form input[type = submit]{
    color: #fff;
    background: #ee6611;
    padding: 12px 0;
    border-radius: 4px;
    cursor: pointer;
    width: 95%;
    justify-content: center;
    text-align: center;
    align-items: center;
}
form .textarea{
    height: 150px;
    border-bottom: 1px solid #eee;
    padding-bottom: 150px;
}
form textarea{
    display: block;
    width: 100%;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    border-bottom: 1px solid #eee;
    font-weight: 300;
}
form p{
    font-size: 13px;
    outline: none;
    width: 100%;
    height: 55px;
    padding: 5px 40px 15px 40px;
    text-align: center;
    
}
form input[type = submit]:hover{
    opacity: 0.9;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
    background-color: #fff;

}
/*alert*/

.konalert{
    flex-wrap: wrap;
    z-index: 10000;
    width: 850px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    background-color: #D4EDDA;
    display: grid;
    position: absolute;
    top: 90px;
    padding: 20px;
    box-shadow: 0px 5px 15px 2px rgba(181,181,181,0.47);
}
.konalert h4{
    outline: none;
    margin: auto;
    width: 100%;
    display: block;
    font-size: 20px;
    color: #145724;
}
.konalert hr{
    margin: auto;
    width: 100%;
    display: block;
    color: #145724;
    width: 100%;
    outline: none;
}
.konalert p{
    margin: auto;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #145724;
    outline: none;
}
.icon{
    border-radius: 50%;
    background: #fafafa;
    width: 70px;
    height: 70px;
    padding-top: 17px;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    float: left;
    margin-left: 45px;
}
.iconrow{
    width: 100%;
    height: 120px;
    position: relative;
    outline: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    bottom: 40px;
}
.textfeld{
    position: relative;
    color: #000;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    float: left;
    top: 20px;
}
.icontext{
    height: 70px;
    width: 165px;
    float: left;
    margin-left: 30px;
}
.iconlink{
    color: #000;
    text-decoration: none;
    top: 15px;
    position: relative;
}
.iconlink:hover, .iconlink:active, .iconlink:visited, .iconlink:focus{
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
/*alert ende*/

@media(max-width: 992px){
    .koncard-content{
        height: fit-content;
    }
    .konform-row{
        text-align: left;
        flex-wrap: wrap !important;
    }
    .koncard-container{
        grid-template-columns: 100%;
        width: 100vw;
        margin: 0;
        padding:  10px 20px;
    }
    .konalert{
        width: 95%;
        }
    .koncard-img{
        height: 330px;
    }
    .textfeld{
        height: auto;
        padding: 0 15px 30px 15px;
    }
   #color{
       padding-left: 10px;
   }
}

@media(max-width: 630px){
    .icontext{
        width: 100%;
        margin-left: auto;
        float: none;
        display: block;
        margin-right: auto;
        height: 60px;
    }
    .icon{
        margin-left: 0px;
        height: 50px;
        width: 50px;
        padding-top: 13px;
    }
    .GrMail, .AiFillPhone, .FaMapMarkerAlt{
        height: 1.8em;
    }
    .iconrow{
        height: 200px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    #icontext1{
        margin-top: 50px;
    }
    .koncard-img{
        height: 400px !important;
        padding-bottom: 10px;
    }
    .iconlink{
        top: 5px;
        float: left;
        left: 15px;
        text-align: left;
    }
    #iconlink2, #iconlink3{
        top: 15px;
    }
    .textfeld{
        padding: 0px 10px 30px 10px;
    }

}
@media(max-width: 450px){
    .konalert{
        height: 200px;
    }
}
@media(max-width: 360px){
    form p{
        height: 85px;
    }
    .iconrow{
        width: 90%;
    }
}
body{
    padding: 0;
    margin: 0;
}

.Impressum{
    padding: 0 20px;
}
.Containerumallesrundherum{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
}

.Container{
    width: 100%;
    height: 100%;
    justify-content: center;
    display: block;
}
h1{
    margin: 10% 0 5% 15% ;
    height: 20px;
    font-family: 'Noto Sans KR', sans-serif;
}
.InnerContainer1{
    margin: 50px 0 0 15%;
    padding: 10px;
    width: 70%;
    position: static;
    border: 1px solid #FAFAFA;
    border-radius: 5px;
    background: #FAFAFA;
    display: block;
    
}
p{
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
}
hr{
    margin: 15px 4px 10px 0px;
    width: 50%;
}
.InnerContainer2{
    margin: 100px 0 100px 15%;
    padding: 10px;
    width: 70%;
    position: static;
    border: 1px solid #FAFAFA;
    border-radius: 5px;
    background: #FAFAFA;
    display:inline-block;
}
.absatz{
    float: left;
    margin: 5% 0 5% 0;
}
@media only screen and (max-width: 1200px){
    .InnerContainer2{
        margin-top: 100px !important;
        margin: 0;
        width: 100%;
        position: relative;
    }

    .InnerContainer1{
        margin-top: 0px !important;
        margin: 0;
        width: 100%;
        position: relative;
        
    }
}

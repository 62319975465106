@import url('https://fonts.googleapis.com/css2?family=Allan:wght@700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
/* font-family: 'Noto Sans KR', sans-serif; */

/* transform: rotateY(180deg);
    transform-origin: left; */

/* orange: #ee6611 */
 *{
    margin: 0;
    padding: 0;
}

 a{
    text-decoration: none;
}

.homeContainer{
    height: 100%;
    width: 100%;
    cursor:url("../../img/home/beer3.gif"), auto;
}

.BackgroundImg{
    position: absolute; 
    /* background-attachment: fixed;  */
    top: 0;
    right: 0;
    z-index: -10;
    object-fit: cover;
    width: 100% !important;
    height: 100vh;
    pointer-events: none;
    transition: 0.5s;
    transition-delay: 0s;
    object-position: center;
    filter: brightness(80%);
    max-width: 100vw !important;
    min-width: 100vw !important;
}

.BackgroundImg img{
    width: 100vw !important;
    height: 100vh;
    object-fit: cover;
    max-width: 100vw !important;
    min-width: 100vw !important;
}
.NavContainer{
    float: right;
    width: fit-content;
    position: absolute;
    top:0;
    right: 0;
    margin-right: 10px;
    margin-top: 10px;
    z-index: 100;
}
 .ul { 
    margin: 0px 0 0; 
    padding: 0; 
    list-style: none; 
    display: table;
    width: 300px;
    text-align: center;
  }
  .li { 
    display: table-cell; 
    position: relative; 
    padding: 0px 0;
  } 
.NavBar{
    float: right;
    width: fit-content;
    top:0;
    right: 0;
    display: flex;
    background-color:#0E655C ;
    color: #fff;
    font-weight: bolder;
    padding: 2px 20px 4px 20px;
    font-size: 23px;
    text-align: center;
    transition: .5s;
    border-radius: 4px;
    cursor: pointer;
    z-index: 100;
    font-family: 'Noto Sans KR', sans-serif;
}
.NavBar:hover{
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}
.NavLink{
    display: inline-block;
    color: #fff;
    line-height: 25px;
    margin: 0;
    padding: 0;
    text-decoration: none;
}
a.NavLink:hover{
    text-decoration: none;
    color: #fff;
}
.NavLink:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: 0.2s ease 0s, left 0.2s ease 0s;
    width: 0;
    text-decoration: none;
    color: #fff;
  }
  .NavLink:hover:after { 
    width: 100%; 
    left: 0; 
    text-decoration: none;
  }
/* .NavLink:hover{
    text-decoration: underline !important;
} */
/* .NavBar a:hover{
    text-decoration: underline !important;
    color: #fff;
} */

.NavBar p{
    line-height: 25px;
    margin: 0;
    padding: 0 7px;
}

.lowNav{
    width: 100%;
    text-align: end;
    margin-right: 10px;
    color: #fff !important;
    font-family: 'Noto Sans KR', sans-serif;
    position: absolute !important;
    top: 52.5px;
    right: 0;
    z-index: 100;
}

.lowNav a{
    color: #fff;
    padding: 0 5px;
    font-weight: 500;
    font-size: 12px;
    position: relative;
}
.lowNav a:hover{
    color: #0e3d38;
}
.LowNavLink{
    position: relative;
}
.LowNavLink:hover{
    cursor: pointer;
}
.fixed{
    position: absolute;

}

.Center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    
    
}
.HeaderContainer{
    display: flex;
}


.Center h1{
    font-family: 'Allan', cursive;
    color: #fff;
    font-size: 11.25rem;
    line-height: 1.2;
    margin: 0;
    height: 120px;
    display: table;
    margin-block-start: 0.0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

}

.Logo{
    display: flex;
    height: 120px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    width: 120px;
}

.ButtonContainer{
    display: flex;
    width: 70%;
    justify-content: center;
    margin-left: 110px;
    margin-top: 20px;
}

.quickNavButtons{
    
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: none !important;
    
    
}

.quickNavButtons p{
    color: #000;
}

.QuiqInfo{
    width: 80%;
    
}
.QuiqInfo h1{
    padding-bottom: 10px;
    width: 450px;
    margin: 0;
   
}
.QuiqInfo p{
    text-decoration: none !important;
    width: 450px;

}


.quickNavButtons p{
    
    background-color:  white;
    font-weight: bolder;
    padding: 7px 20px;
    font-size: 25px;
    text-align: center;
    text-decoration: none !important;
    transition: .5s;
    border-radius: 4px;
   
    
}


.quickNavButtons a{
    text-decoration: none !important;
    cursor:url("../../img/home/beer4.gif"), auto;
    padding: 0;
    margin: 0;
    height: fit-content;
    text-decoration: none;
}

.quickNavButtons a:hover{
    text-decoration: none;
}

.quickNavButtons p:hover{
   background-color: #ffd156;
 
    cursor:url("../../img/home/beer4.gif"), auto;
    text-decoration: none;
    
}
.felxBox{
    display: flex;
}
.FeedContainr{
    background-color: #fff;
    width: 100%;
    height: fit-content;
    z-index: 100 !important;
    position: relative;
    cursor: initial !important;
    font-family: 'Noto Sans KR', sans-serif;
    padding-bottom: 100px;
    padding-top: 5px;
    
}
.scrolldown{
    width: 100%;
    height: fit-content;
    position: absolute;
    z-index: 5 !important;
}
#srollInfo{
    color: rgb(226, 226, 226);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-35px);
    
}
.FirstRow{
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 30px 0 ;
    display: flex;
    justify-content:space-between;

}
.WelcomeImg{
    width: fit-content;
    
}
.WelcomeImg img{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: flex-end;
    object-fit: cover;
    border-top-left-radius: 10px;
        border-top-right-radius: 10px;

}
#AnfahrtIMg img{
    width: fit-content;
    height: fit-content;
        border-radius: 10px;
        
}

.Welcome{
    width: 100%;
    height: fit-content;
    
    padding:0 0 15px 15px;
    border-bottom: 1px solid #ccc;
    margin-right: 20px;
}

.Welcome p{
    width: 700px;
    line-height: 1.5;
    font-size: small;
}
.WelcomeBox{
    position: relative;
    margin-left: 70px;
   
}
.WelcomeText{
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    background-color: rgb(250, 250, 250);
    text-align: left;
    padding: 10px 20px;
}
.Anfahrt{
    width: 100%;
    height: fit-content;
    padding:0 0 15px 15px;

    margin-left: 20px;
}

.Anfahrt p{
    width: 600px;
    margin: 0;
}

.Anfahrt a{
    color: #000;
    font-weight: bold;
    text-decoration: none; 
}

.SecondRow{
    display: flex;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    margin-top: 100px;
}

.lastRow{
    display: flex;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    margin-top: 100px;
}

.ExtLinks{
    display: flex;
    
    padding-left: 15px;
}

.ExtLinks p{
    margin: 5px;
    margin-left: 0;
}

.ExtLinks a{
    color: #000;
    text-decoration: none;
    margin: 5px;
    padding: 2px 4px;
    margin-left: 0;
    padding-left: 0;
}

.ExtLinks a:hover{
    color: #fff;
    background-color: #ee6611;
    padding: 2px 4px;
    border-radius: 2px;
}
@media only screen and (max-width: 1700px){
    .BackgroundImg img{
        width: 100vw !important;
        height: 100vh;
        object-fit: cover;
        max-width: 100vw !important;
        min-width: 100vw !important;
    }
    .BackgroundImg .carousel-inner{
        max-width: 100vw !important;
    }
}
@media only screen and (max-width: 1300px){
    .NavContainer{
        margin: 0;
        display: block;
        width: fit-content;
        padding: 10px;
        text-align: center;
        position: absolute;
        
    }
    .NavBar{
        width: fit-content !important;
        text-align: center;
        font-size: large;
        position: relative !important;
        float: right;
        width: fit-content;
        top:0;
        right: 0;
        display: flex;
        background-color:#0E655C ;
        color: #fff;
        font-weight: bolder;
        padding: 7px 20px;
        font-size: 18px;
        text-align: center;
        transition: .5s;
        border-radius: 4px;
        cursor: pointer;
        z-index: 100;
        
    }
    .NavLink{
        font-weight: bolder;
        
    }
    .LowNav{
       position: absolute;
       top:40px !important;
       margin-right: 0 !important;
    }
    .BackgroundImg img{
    width: 100vw !important;
    height: 100vh;
    object-fit: cover;
    max-width: 100vw !important;
    min-width: 100vw !important;
    }

    .BackgroundImg .carousel-inner{
        max-width: 100vw !important;
    }
    
    
    .ButtonContainer{
        margin: auto !important;
        width: 90%;
    }
    .quickNavButtons p{
        width: 100%;
         padding: 5px 10px ;
        margin: 0;
        font-size: medium;
        color: #0e3d38 !important;
    }
    .quickNavButtons p:hover { color: inherit; }
    .HeaderContainer{
        width: 100%;
    }

    .HeaderContainer h1{
        font-size: 57px !important;
        height: fit-content;
        padding-top: 10px;
        color: #fff;
    }

    .HeaderContainer img {
        height: 80px !important;
    }
    .FirstRow{
        display: initial;
        padding: 0;
    }
    .lastRow{
        margin: 20px !important;
        margin: auto;
        width: 90%;
    }
    .SecondRow{
        display: initial;
        width: 100% !important;
        height: 100% !important;
        padding: 20px !important;
    }
    .Welcome{

        padding: 15px 0;
        width: fit-content !important;
        margin: auto !important;
        width: 90% !important;
    }

    .Welcome h1{
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .Welcome p{
        width: 100%;
    }
    .WelcomeImg img{
        max-width: 450px;
        width: 100%;
        height: auto;
        margin-left: auto !important;
        margin-right: auto !important;  
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }
    .WelcomeText{
        background-color: rgb(241, 241, 241);
        text-align: left;
        padding: 10px 20px;
    }
    .WelcomeImg{
        max-width: 450px;
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important; 
        height: auto;  
        
    }
    .WelcomeBox{
        max-width: 450px;
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 30px;
        padding: 20px 15px !important;
    }
    #AnfahrtIMg{
        display: none;
    }
    .Anfahrt{
        padding: 0;
        margin: auto;
        width: fit-content;
    }
    .Anfahrt p{
        width: 100%;
    }
    .ExtLinks{
    display: flex;
    padding-left: 0;
    width: 90%;
    margin: 0 auto;
    }

    .fixed{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        position: absolute !important;
    }
    .HeaderContainer{
        margin-left: auto;
        margin-right: auto;
       width: 300px;
       display: flex;
       justify-content: center;
   
        
    }
    .ButtonContainer{
        width: 270px;
    }
    .Center{
        margin: 0 !important;
        width: 100%;
        height: 100vh;
    }
    .CardContainer{
        padding: 20px 15px !important;
    }
    .Logo{
        display: none;
    }
}

@media only screen and (max-width: 400px){
    .NavContainer{
        width: 100%;
    }
    .NavBar{
       
        text-align: center;
        font-size: 15px;
    }
    .LowNavLink{
        font-size: 12px;
        top: -5px !important;
    } 

 } 
 @media only screen and (max-width: 400px){
     .NavBar{
         padding: 5px 5px;
         left: 5px;
     }
     .li{
         padding: 0;
     }
 }

 @media only screen and (max-width: 350px){
     .NavBar{
         width: 100%;
         font-size: 15px;
     }
     .NavBar p{
         padding: 2px;
     }
     .li{
         padding: 0;
     }
 }
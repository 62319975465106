@import url('https://fonts.googleapis.com/css2?family=Allan:wght@700&display=swap');



.BackLink{
        margin: 0 !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
    }
.Gallerie{
    margin-left: 20px;
    margin-right: 20px;
}

.imgContainer{
    margin: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 100px;
}

.imgContainer img{
    width: 400px;
    height: 280px;
    object-fit: cover;
}

.GallerieTop{
    max-width: 1290px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: auto;
    margin-bottom: 20px;
}

.PageHeadding{
    transform: translateX(-15px);
    width: 100%;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    
    font-weight: 550;
     display: flex;
     justify-content: center;
}

.PageHeadding p{
    
    font-size: 40px;
    line-height: 40px;
    font-family: 'Noto Sans KR', sans-serif;
    padding: 0;
    
}
#displayNone{
    padding-left: 10px;
}
@media only screen and (max-width: 1300px){
    .imgContainer{
        margin: auto;
        grid-template-columns: repeat(2, 1fr);
        
    }
    .PageHeadding p{
    transform: translateX(0px) !important;
    
}
}

@media only screen and (max-width: 850px){
    .imgContainer{
        margin: auto;
        grid-template-columns: repeat(1, 1fr);
        
    }

    .imgContainer img{
        max-width: 400px;
        width: 100%;
        height: 280px;
        object-fit: cover;
    }
    .BackLink{
        margin: 0 !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
        z-index: 100;
    }

    .tooltipCont{
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
    #displayNone{
        display: none;
    }
    
    .PageHeadding{
        height: 38px;
      
    }
    .PageHeadding p{
       font-size: 34px;
       line-height: 34px;
       font-weight: bold;
    }
}

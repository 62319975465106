.PhiloContainer{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
    padding: 0 20px;
    
}

.textContainer{
    max-width: 1250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    
}
.textContainer g{
 font-weight: 800;
 font-size: 20px;
 line-height: 1;
}
.PhiloText{
    line-height: 2;
}
.firsimg{
    display: flex;
}

.firsimg img{
    width: 500px;
    left: 40px;
    height: auto;
    margin-bottom: 20px;
    margin-left: 40px;
}

@media only screen and (max-width: 1200px){
    .firsimg img{
    width: auto;
    height: 300px;
    margin-bottom: 20px;
    margin-left: 20px;
}
}

@media only screen and (max-width: 900px){
    /* .PhiloText{
        text-align: center;
    } */
    .firsimg img{
        display: none;
    }

}
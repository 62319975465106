.LisContainer *{
    margin: 0 ;
    padding: 0 ;
    /* font-size: initial;
    position: relative;
    font-family:initial;
    font-weight: initial;
    line-height: initial;
    color: initial; */
}
.LisContainer{
    width: 95%;
    margin: 0 auto;
    max-width: 100vw;
}

#HeadPart d{
    font-weight: 600;
}
.LisContainer h1 {
    position: relative;
    height: 100%;
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0;
    text-decoration: underline;
}
.LisContainer h2{
    font-size: 1.7rem;
    font-weight: 600;
}
.LisContainer p{
    padding-bottom: 30px;
    font-size: 16px;
}

.LisContainer .DatePicker{
    margin: 0 !important;
    padding: 0;
    padding-top: 10px;
    font-style: initial !important;
    font-size: 16px;
}

.LisContainer select{
    margin: 20px 0 !important;
    font-size: 16px;
}

.LisContainer input{
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 16px;
}
input[type=checkbox]{
    padding: 0px;
    margin: 0;
    margin-left: 5px;
    margin-top: 4px;
    width: 30px;
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
    margin-left: 0;
}
.checkbox label, .radio label{
    padding-left: 30px;
}
  .checkbox {
    border: 1px solid transparent;
  }
  
  .checkbox input {
    float: right;
  }
#inputDescription{
    text-align: start !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    height: fit-content;
    padding-top: 20px;
    color: #000;
    text-decoration: underline;
}

.form-cor{
    border: 1px solid black;
    padding: 10px;
    margin: 30px 0;
}

.Uhrzeitasuwahl{
    display: flex;
}

#abschicken{
    margin-top: 50px;
    width: 100%;
}



 .Speisekarte{
    margin: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .SpeisekarteInnerContainer{
    width: 1250px;
    height: 100%;
    margin: auto;
    /* position: fixed; */
    
 }
 
 .SpeisekarteTop{
     box-sizing: border-box;
     width: 100%;
     height: 50px;
     margin-top: 25px;
     margin-bottom: 15px;
     padding-bottom: 10px;
     display: flex;
     justify-content: space-between;
     border-bottom: 1px solid #ccc;

 }
#tel{
    position: absolute;
    top: 20px;
    right: 0;
}
#rellative{
    position: relative;

}
 .BackLink{

    
     margin-left: 20px;
 }
 .SpeisekartenBoddy{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    height:calc(100vh - 150px);
    }

 input{
     display: none;
 }

 .SpeisekartenContainer{
     display: flex;
     justify-content: space-around;
     width: 100%;
     height: 90%;
     z-index: 1;
}

 #cover{
     width: 100%;
     height: 100% -50px;
     position: relative;
     padding-right: 20px !important;
     padding-left: 20px;
     transition: 0s;
     overflow: auto;
 }

 #cover h1{
     width: 100%;
     max-width: 550px;
     padding-bottom: 15px;
     border-bottom: 1px solid black;
     margin-bottom: 10px;
     height: fit-content;
     margin-left: 0;
    font-family: 'Noto Sans KR', sans-serif;
     font-weight: 550;
     margin-top: 0;
     margin-left: auto;
 }

 .Gericht{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 550px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0px;
    padding-top: 10px;
    margin-left: auto;
 }
.Beschreibung{ 
    margin-bottom: 0;
    margin-left: 10px;
    font-family: 'Noto Sans KR', sans-serif;
}

.Preis{
    padding-left: 30px;
}
 .page-container{
    width: 100%;
    height: 100%;
    position: relative;
    perspective:2000px ;
    /* border-left: 3px solid black; */
    
 }



 .page{
     width: 100%;
     height: 100%;
     position: absolute;
     top:0;
     left:0;
     transform-origin:left ;
     transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
     transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transfrom-sytle: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    
     transition: 2s;
     color: white;
 }
 
 .page img{
    width: auto;
    height: 100%;
    
 }
 .front, .back{
     position: absolute;
     width: auto;
     height: 100%;
     top: 0;
     left: 0;
     -webkit-box-shadow: 0px 0px 3px 1px rgb(231, 231, 231);
    -moz-box-shadow: 0px 0px 3px 1px rgb(231, 231, 231);
    box-shadow: 0px 0px 3px 1px rgb(231, 231, 231);
 }

 .front{
     -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
     background-color: white;
     box-sizing: border-box;
 }

 .back{
     z-index: 1;
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -moz-backface-visibility: hidden;
     backface-visibility: hidden;
     background-color: white;
 }

 .next-btn{
     position: absolute;
     bottom: 13px;
     right: 13px;
     cursor: pointer;
     background-color: #ee6611;
     color: #fff;
     padding: 2px 5px;
     border-radius: 2px;
 }

 .back-btn{
      position: absolute;
     bottom: 13px;
     left: 13px;
     cursor: pointer;
     background-color: #ee6611;
     color: #fff;
     padding: 2px 5px;
     border-radius: 2px;
 }

 #none{
     display: none;
 }
 .KartenAussucher{
     display: none;
    
 }

 .KeineTageskarte{
     font-weight: bold;
     width: 100%;
    max-width: 550px;
    margin-left: auto;
 }

 .firefoxMenü{
    width: 100%;
    display: flex;
    justify-content: space-around;
 }
 .firefoxMenü .CarouselContainer{
     display: block !important;
     width: 50vw !important;
     position: relative !important;
     z-index: 100;
 }
 .firefoxMenü #none{
     display: initial !important;
 }

 .firefoxMenü .slidImg{
     height: 80vh !important;
 }

 
 #KartenCintainr{
     display: block !important;
 }
 .firefoxMenü #cover{
     width: 50vw;
 }
 .firefoxMenü .Gericht{
     margin: 0 !important;
 }
 .firefoxMenü h1 {
     margin: 0 !important;
 }
 @media only screen and (max-height: 750px){
     #none{
         display: initial !important;
         max-width: 500px !important;
     }
     .carousel-inner{
        max-width: 350px !important;
         height: 100%;
         margin-left: auto;
         margin-right: auto;
     }
 }

 @media only screen and (max-height: 670px){
    .carousel-inner{
        max-width: 100% !important;
       
     }
 }
 @media only screen and (max-height: 650px){
    .carousel-inner{
        max-width: 100% !important;
         
     }
 }
#smallSpeise{
    display: none;
    
}
#BigSpeise{
display: initial;
}
.Download{
    width: 320px;
    position: absolute;
    text-align: center;
    justify-content: baseline;
    align-items: baseline;
    bottom: 40px;
    z-index: 0;
}
.DownloadP{
    padding: 5px;
    float: left;
    position: relative;
    font-size: 15px;
}
.downloadbutton{
    width: 160px;
    height: 35px;
    background: #fff;
    color: #9a9a9a;
    border: solid #9a9a9a 1px;
    border-radius: 4px;
    margin: 0px 0 0 0px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    float: left;
    position: relative;
    margin: 0px 0px 0 0px !important;
}
.downloadbutton:hover{
    opacity: 0.7;
    background-color: #fff;
    color: #ee6611;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    background-color: #fff;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
    color: #ee6611;
    border-color: #fff;
    background-color: #fff;
}
.btn:active{
    box-shadow: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus{
    box-shadow: none;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus{
    outline: none;
}
.downloadbutton{
    float: right;
    border: none;
    color: #ee6611;
}
 @media only screen and (max-width: 1300px){
     .firefoxMenü{
         display: none;
     }
     .HandyNavSpeise{
         padding: 0 20px;
         width: 100% !important;
         
     }
     .tageswrapper{
        
         padding: 10px  20px 20px 20px;
     }
     #cover{
         margin-top: 50px;
         
         
         width: fit-content;
         margin-left: auto;
         margin-right: auto;
         border-radius: 10px;
         border: 1px solid #ccc;
         
     }
     #cover h1{
         display: none;
     }
     .Gericht{
         margin-left: auto;
         margin-right: auto;
         display: block;
         text-align: center;
         padding: 10px 0 10px 0;
     }
     .Gericht p{
         padding: 0;
         margin: 0 !important;
     }
     .Preis{
         padding-top: 10px !important;
         font-size: smaller;
     }
      #smallSpeise{
        display: initial !important;
        }
        #BigSpeise{
            display: none !important;
        }
     #Selected{
         
         width: 100%;
         text-align: center;
         padding-bottom: 5px;
     }
     #notSelected{
         width: 100%;
         text-align: center;
         padding-bottom: 5px;
     }
     .StrichYES{
        border-bottom: 2px solid black;
     }
     .StrichNO{
         border-bottom: none ;
     }
     .KartenAussucher{
         position: relative;
         display: flex;
         
         border-bottom: 0.5px solid #ccc;
         width: 100% !important;
         justify-content: space-around;
         
         
     }
     .KartenCintainr{
         margin: 0 !important;
     }
     #rellative{
         position: initial !important;
         border-bottom: none !important;
         margin: 0 !important;
     }
     #bigspeise{
         display: none !important;
     }
     #none{
         display: initial !important;
         max-width: 600px;
     }
     .carousel-inner{
        max-width: 450px;
         height: 100%;
         margin-left: auto;
         margin-right: auto;
     }
    
     .slidImg{
        
        max-width: calc(100% - 20px);
        height:auto;
        margin: 10px !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        border: 1px solid #ccc;
    }
    .GallerieTop{
        width: 100%;

        
        
    }
    
    .fixed{
        position: absolute;
        margin: auto;
    }
    .Speisekarte{
    
        height: 100%  !important;
        /* min-height: 100vh !important; */
    }
    #tel{
        top: 100px;
        
        left: 20px;
        right: initial;
    }

    .Download {
        width: 100vw;
        position: absolute;
        text-align: center;
        justify-content: top !important;
        align-items: top !important;
        top: 100px;
        z-index: 0;
        padding-right: 30px !important;
    }
    .downloadbutton {
        background-color: #ececec;
    }

    .DownloadP {
        display: none;
    }

    .fixed{
        margin-top: 50px;
    }
    
    
 }
 @media only screen and (max-width: 450px){
    .slidImg{
     width: calc(100% - 20px);
     height:auto;
     margin: 10px;
     border: 1px solid #ccc;
    }
    
    .Download{
        bottom: 5px;
        padding-right: 10px;
    }
    
    
 }
 

 #p1{
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
     z-index: 7;
 }

 #p2{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 6;
 }

 #p3{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 5;
 }

 #p4{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 4;
 }

  #p5{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 3;
 }
 #p6{
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
        z-index: 2;
 }
 #p7{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 1;
 }
 #p8{
     -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform-origin: left;
     transition: 2s;
    z-index: 0;
 }
#p9 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -1;
}
#p10 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -2;
}
#p11 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -3;
}
#p12 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -4;
}
#p13 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -5;
}
#p14 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -6;
}
#p15 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -7;
}
#p16 {
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    transition: 2s;
    z-index: -8;
}

 #c1:checked ~ #cover{
    visibility: hidden;
    transition: 1s;
}

 #c1:checked ~ .page-container #p1{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 8;
     
     position: absolute;
}

#c2:checked ~ .page-container #p2{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 9;
     position: absolute;
}
#c3:checked ~ .page-container #p3{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 11;
     position: absolute;
}

#c4:checked ~ .page-container #p4{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 13;
     position: absolute;
}  

#c5:checked ~ .page-container #p5{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 15;
     position: absolute;
} 
#c6:checked ~ .page-container #p6{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 17;
     position: absolute;
} 

#c7:checked ~ .page-container #p7{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 19;
     position: absolute;
} 
#c8:checked ~ .page-container #p8{
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
     transform: rotateY(-180deg);
     transform-origin: left;
     transition: 2s;
     z-index: 21;
     position: absolute;
} 

#c9:checked~.page-container #p9 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c10:checked~.page-container #p10 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c11:checked~.page-container #p11 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c12:checked~.page-container #p12 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c13:checked~.page-container #p13 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c14:checked~.page-container #p14 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c15:checked~.page-container #p15 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}

#c16:checked~.page-container #p16 {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform-origin: left;
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform: rotateY(-180deg);
    transform-origin: left;
    transition: 2s;
    z-index: 23;
    position: absolute;
}


.randLinks{
    padding-left: 20px; 
    background-color: green;
    box-sizing: initial;
    position: relative;
    z-index: 0;
}
.global-class-name {
    margin: 3px;
  }
.tooltipCont {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 38px;
  border-radius: 5px;
  
}
.tooltipCont .tooltip {
  visibility: visible;
  display: block;
  width: 120px;
  background-color: #292b29e0;
  color: black;
  text-align: center;
  padding: 5px 0;
  
  margin-top: 3px;

}

.tooltipCont:hover .tooltip {
  visibility: visible;
}
 








body{
    padding: 0;
    margin: 0;
}
.Containerumallesrundherum{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}
.Containerumallesrundherum .BackLink{
    margin: 0 67.5% 45% 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding: 0 0 0 0 !important;
    margin-bottom: 20000px;
}
.Container{
    width: 100%;
    height: 100%;
    justify-content: center;
}
.InnerContainer{
    margin: 0px 0 5% 15%;
    padding: 10px;
    width: 70%;
    position: relative;
    border: 1px solid #FAFAFA;
    border-radius: 5px;
    background: #FAFAFA;
    
}
h4{
    margin-top: 50px;
}

@media only screen and (max-width: 1200px){
    .InnerContainer{
        margin-top: 0px !important;
        margin: 0;
        width: 100%;
        position: relative;
    }

}
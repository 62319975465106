body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  *,*:before,*:after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .container{
      max-width: 1170px;
      margin: auto;
  }
  .row{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  ul{
      list-style: none;
  }
  .footer{
      background-color: #FAFAFA;
      padding: 50px 0;
    z-index: 100;
    position: absolute;
    width: 100%;
  }
  .footer-col{
      width: 25%;
      padding: 0 20px;
      justify-content: center;
  }
  .footer-col .logo{
      width: 120px;
      height: 120px;
  }
  .footer-col .nachOben{
      width: 110px;
      height: 110px;
      background-color: #FFD35D;
      border-radius: 50%;
  }
  .footer-col ul li .nachOben:hover{
      padding-left: 0px;
      padding-top: -10px;
  }
  .footer-col ul li .nachOben img:hover{
      padding-left: 0;
      margin-top: 20px;
      transition: 0.3s;
  }
  .footer-col .nachOben img{
      margin: 22.5px 0 0 25px;
  }
  .footer-col .logo:hover{
      padding-left: 0;
  }
  .footer-col h4{
      font-size: 18px;
      color: black;
      text-transform: capitalize;
      margin-bottom: 35px;
      font-weight: 500;
      position: relative;
  }
  .footer-col h4:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      background-color: rgb(230, 228, 228);
      height: 1px;
      box-sizing: border-box;
      width: 200px;
  }
  .footer-col{
      list-style: none;
  }
  .footer-col ul li:not(:last-child){
      margin-bottom: 10px;
  }
  .footer-col ul li a{
      font-size: 16px;
      color: #000;
      text-decoration: none;
      font-weight: 300;
      color: #000;
      display: block;
      transition: all 0.3s ease;
  }
  .footer-col ul li a:hover{
      color: rgb(128, 128, 128);
      padding-left: 8px;
  }
  .footer-col .social-links li a img{
      width: 50px;
      height: 50px;
    margin: 5% 5% 0 0;
  }
  .footer-col .social-links a{
      display: inline-block;
      height: 65px;
      width: 65px;
      background-color: rgb(230, 228, 228);
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: #ffffff;
      transition: all 0.5s ease;
        justify-content: center;
        align-items: center;
      padding: 5px 0 0 5px;
  }
  .footer-col .social-links a:hover{
      color: #24262b;
      background-color: #FFD35D;
  }
  .footer-col .we{
      color: #000;
      text-decoration: none;
  }
  .nachOben{
      flex: 50%;
      text-align: right;
      margin-right: -340px;
  }
  .nachOben button{
      border: solid rgb(230, 228, 228) 2px;
      border-radius: 50%;
      padding: 10px;
  }
  .nachOben button:hover{
      background-color: #FFD35D;
      transition: all .3s ease;
  }
  .links{
      text-align: center;
      width: 100%;
  }
  .links a{
      color: #000;
      padding: 10px;
  }
  /* responsive */
@media(max-width: 992px){
    .footer-col{
        width: 31%;
    }
}
@media(max-width: 767px){
    .footer{
      background-color: rgb(241, 241, 241);
    }
      .footer-col{
          width: 50%;
          margin-bottom: 30px;
          justify-content: center;
          text-align: center;
      }
      .footer-col h4{
          border-bottom: 1px solid #bbbbbb;
          padding-bottom: 7px;
          margin-bottom: 20px;
          width: 200px;
          margin-left: auto;
          margin-right: auto;
      }
     .footer-col h4:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: #bbbbbb;
        height: 0px;
        box-sizing: border-box;
        width: 200px;
}
  }
  @media(max-width: 574px){
      .footer-col{
          width: 100%;
      }
  }
*{
    padding: 0 ;
    margin: 0 ;
    box-sizing: border-box;
}

.Headding{
    font-size: xx-large !important;
    font-weight: 700;
    padding-bottom: 5px;
    margin: 0;

}

.CardContainer{
    width: 33%;
}

.Card{
    height: 400px;
    /* -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%); */
    background-color: rgb(250, 250, 250);
    padding: 20px;
    border-radius: 10px;
}

#spacer{
    margin-right: 50px;
}
.Oeffnungszeiten{
    margin-top: 10px;
}
#discription{
    font-weight: 600;
    margin: 0;
}

#time{
    padding-top: 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
#Offdesc{
    margin-top: 140px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

#middleoff{
    height: 100%;
    margin-top: 0px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

#underline{
    
    text-decoration: underline;
}
.AktuellesText{
    overflow: auto;
    height: 220px;
    color: rgb(44, 44, 44);
}

.AktuellesText p{
    line-height: 150%;
}
@media only screen and (max-width: 1300px){
    .CardContainer{
        max-width: 450px;
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 20px;
    }
    .Card{
        background-color: rgb(241, 241, 241);
    }
}

/* .oeffnungszeitenContainer{
    width: 100%;
}
.ÖffnungsZeiten{
    background-color: #fff;
    color: #000;
    width: 300px ;
    height: 300px;
    margin: 0px 0px;
    transform: translate(0px, 80px);
    margin-left: 200px;
    -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
    font-family: 'Noto Sans KR', sans-serif;
    padding-top: 20px;
}

.ÖffnungsZeiten img{
    width: 100%;
}

.AktuellesContainer{
    width: 100%;
}

.Aktuelles{
    background-color: #fff;
    color: #000;
    width: 450px;
    height: 250px;
    padding-top: 30px;
    -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);

}

.EventContainer{
    width: 100%;
    margin-top: 50px;

}

.Veranstaltungen{
    background-color: #fff;
    color: #000;
    width: 100%;
    padding: 20px 0;
    -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 11%);
}

.oeffnungszeitenContainer #one {
    width: 300px;
    position: absolute;
    z-index: -1;
}

.oeffnungszeitenContainer #two {
    width: 400px;
    position: absolute;
    transform: translate(40px,240px);
    z-index: -1;
}

.oeffnungszeitenContainer #thre{
    height: 370px;
    position: absolute;
    transform: translate(440px,20px);
    z-index: -1;
}

.Background{
    text-align: center;
    padding: 0px 25px;
}

.Aktuelles img{
    width: 100%;
}
.AktuellesContainer .Heading {
    height: 40px;
    text-align: start !important;
}
.AktuellesContainer p {
   
    text-align: start !important;
}

.EventContainer .Heading h1{
    height: 40px;
    text-align: start !important;
    margin: 0 !important;
}
.EventContainer p{
    height: 40px;
    text-align: start !important;
}
.AktuellesContainer h1{
    margin: 0 0;
}
.information{


}

.information p{

}
.information h1 {
    margin: 0;
}
.information h2 {

}

.information h3 {
    font-weight: 400;
    text-decoration: underline;
    font-size: large;
} */
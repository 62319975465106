.aktuellesInput{
    width: 100%;
    font-size: 18px;
    height: 200px;
}

.adminImageAktVer{
    width: 40px;
    height: 40px;
}

.AktVerList{
    display: flex;
    list-style-type: none;
}

.AktVerList li h2{
    margin-left: 10px;
    font-size: 23px;
}

#adminAlert{
   background-color: #9eddab;;
   padding: 5px;
   border-radius: 5px;
   position: absolute;
   width: 95%;
   text-align: center;
}

.Password{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
}

.Password form{
    width: 100%;
    max-width: 800px;
    padding: 20px;
    border: 1px solid black;
}

.Password h1{
    margin: 0 !important;
    padding: 20px !important;
    line-height: 20px;
    font-size: 15px;
}

.Password input{
    border: 1px solid #ccc;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');


element > element{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body{
    font-family: 'Noto Sans KR', sans-serif !important;
}

/* Datepicker */
.alertwrapper{
    position: relative;
    z-index: 100;
    transition: 5s;
}
.react-datepicker-wrapper{
    min-width: 100%;
}

.DatePickerWapper1{
    min-width: 100%;
    display: flex;
    flex-wrap: nowrap !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    align-items: baseline;
}
.react-datepicker {
    font-size: 1.3rem !important;
  }
  
  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }
  
  .react-datepicker__header {
    padding-top: 6px !important;
  }
  
  .react-datepicker__navigation {
    top: 13px !important;
  }
  
  .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
      background-color: #FFD155;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: #FFD155;
  }
.Reservieren  form .DatePicker{
    color: #9a9a9a;
    display: block;
    min-width: 355px;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    border-bottom: 1px solid #eee;
    font-weight: 300;
    line-height: inherit;
}


  /* Datepicker ende */

.Reservieren{
    background-color: #fafafa;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 40px;
    padding: 0 20px 40px 20px;
}
#color{
    color: #000;
}
.banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.card-container{
    display: grid;
    width: 850px;
    margin: 0 0 0 0;
}
.card-img{
    width: 100%;
    
    height: 300px;
    background: url("../img/card-img.png") center cover no-repeat;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    z-index: 1;
}
.banner h2{
    padding-bottom: 40px;
    margin-bottom: 20px;
}
.card-content{
    background: #fff;
    height: 103%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.card-content h3{
    text-align: center;
    color: #000;
    padding: 25px 0 10px 0;
    font-size: 26px;
    font-weight: 500;
}
.FormWrap{
    margin-top: 30px;
}
.form-row{
    display: flex;
    flex-wrap: nowrap !important;
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    align-items: center;
}
.Reservieren form .DatePicker{
    color: #9a9a9a;
    display: block;
    min-width: 355px;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    border-bottom: 1px solid #eee;
    font-weight: 300;
    line-height: inherit;
}
form select, form input{
    display: block;
    width: 100%;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    border-bottom: 1px solid #eee;
    font-weight: 300;
}
form input[type = text], form input[type = number], form input[type = date], select{
    color: #000;
}
form input::placeholder{
    color: #464141;
}
form input[type = submit]{
    color: #fff;
    background: #ee6611;
    padding: 12px 0;
    border-radius: 4px;
    cursor: pointer;
    width: 95%;
    justify-content: center;
    text-align: center;
    align-items: center;
}
form .textarea{
    height: 70px;
    border-bottom: 1px solid #eee;
    padding-bottom: 70px;
}
form p{
    color: #ee6611;
    font-size: 13px;
    outline: none;
    width: 100%;
    height: 55px;
    padding: 5px 40px 15px 40px;
    text-align: center;
    
}
form input[type = submit]:hover{
    opacity: 0.9;
}

.download{
    display: flex;
    flex-wrap: nowrap !important;
    width: 90%;
    height: 220px;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.downloadP{
    display: block;
    color: #9a9a9a;
    width: 100%;
    margin: 15px 12px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    border: none;
    
}
.downloadButton{
    width: 160px;
    height: 35px;
    background: #fff;
    color: #9a9a9a;
    border: solid #9a9a9a 1px;
    border-radius: 4px;
    margin: 100px 0 0 0px;
    padding: 5px;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif !important;
    outline: none;
    position: absolute;
    
}
.downloadButton:hover{
    opacity: 0.7;
    background-color: #fff;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
    background-color: #fff;

}
/*alert*/

.alert{
    flex-wrap: wrap;
    z-index: 10000;
    width: 850px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    background-color: #D4EDD9;
    display: grid;
    position: relative;
    top: 30px;
    box-shadow: 0px 5px 15px 2px rgba(181,181,181,0.47);
}
.alert h4{
    outline: none;
    margin: auto;
    width: 100%;
    display: block;
    font-size: 20px;
    color: #145724;
}
.alert hr{
    margin: auto;
    width: 100%;
    display: block;
    color: #145724;
    width: 100%;
    outline: none;
}
.alert p{
    margin: auto;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #145724;
    outline: none;
}

/*alert ende*/

/* .MobileDatePicker{
    display: none;
} */

@media(max-width: 992px){
    .card-content{
        height: fit-content;
    }
    .form-row{
        text-align: left;
        flex-wrap: wrap !important;
    }
    .card-container{
        grid-template-columns: 100%;
        width: 100vw;
        margin: 0;
        padding:  10px 20px;
    }
    .alert{
        width: 95%;
        }
    .card-img{
        height: 330px;
        display: none;
    }
   #color{
       padding-left: 10px;
   }
}
@media(max-width: 600px){
    form .DatePicker{
        min-width: 95%;
    }
}
@media(max-width: 450px){
    form .DatePicker{
        min-width: 93%;
    }
    .downloadP{
        max-width: 250px;
    }
    .downloadButton{
        margin: 60px 0 0 0;
    }
    .alert{
        height: 200px;
    }
}
@media(max-width: 360px){
    form .DatePicker{
        min-width: 91%;
    }
    form p{
        height: 85px;
    }
}
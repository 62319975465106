.appContainer{
    min-height: 100vh;
    height: 100%;
    width: 100%;
}
.CookieConsent{
    z-index: 999999;
}



